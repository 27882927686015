import http from "@/utils/request";


/**
 * 商家查询待答问卷列表
 */
export function getAnsweredQuestionList(data) {
	return http({
		method: "post",
		url: "/baseSurveyQuestion/getAnsweredQuestionList.nd",
		data: data
	});
}
